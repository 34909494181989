import useWatch from "@classdojo/web/hooks/useWatch";
import isRetinaEnvironment from "is-retina";
import { useState } from "react";
import { ImportGlobFunction } from "vite/types/importGlob";

type WebpackRequireContext = {
  keys: () => string[];
  (id: string): string;
};

let imagesWebpack: WebpackRequireContext;
let imagesVite: ReturnType<ImportGlobFunction>;
let prefix: string;

// These ESLint / ts-ignores shouldn't be necessary, but I couldn't figure out how to
// get it to work in every environment that `web` is used in:
if (Config.bundler === "vite") {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  imagesVite = import.meta.glob<string>("../**/*.(png|gif|jpeg|jpg|svg|webp)", {
    query: "?url",
    import: "default",
    eager: true,
    //    eager: Config.nodeEnv === "production",
  });
  prefix = "..";
} else {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  imagesWebpack = require.context("app/", true, /.+\.(png|gif|jpe?g|svg|webp)$/);
  prefix = ".";
}

const TRANSPARENT_PNG_DATA_URL =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

function fileExists(id: string): boolean {
  if (imagesVite) return id in imagesVite;
  if (imagesWebpack) return imagesWebpack.keys().includes(id);
  throw new Error("invalid useImageSrc builder - not webpack or vite?");
}

export function getImageSource(path?: string): string {
  const [, file, extension] = path?.match(/^(.*?)\.(\w{3,4})$/) || [];

  const plainPath = `${prefix}/${file}.${extension}`;
  const retinaPath = `${prefix}/${file}@2x.${extension}`;

  const shouldUseRetina = isRetinaEnvironment() && fileExists(retinaPath);
  const resolvedPath = shouldUseRetina ? retinaPath : plainPath;

  if (!fileExists(resolvedPath)) {
    // eslint-disable-next-line no-console
    console.warn(`Couldn't locate image ${resolvedPath}`);
    return TRANSPARENT_PNG_DATA_URL;
  }

  if (imagesWebpack) return imagesWebpack(resolvedPath);
  if (imagesVite) return imagesVite[resolvedPath] as string;

  throw new Error("invalid getImageSource builder - not Webpack or Vite?");
}

export function useImageSrc(path?: string): string {
  const [imageSrc, setImageSrc] = useState<string>(TRANSPARENT_PNG_DATA_URL);
  useWatch(path, () => setImageSrc(getImageSource(path)));
  return imageSrc;
}

type ImageProps = {
  alt: string;
  src: string;
};

export const Image = ({ src, alt, ...rest }: Record<string, unknown> & ImageProps) => {
  const resolvedSrc = useImageSrc(src);

  if (!resolvedSrc) return <img {...rest} alt={alt} />;
  return <img src={resolvedSrc} {...rest} alt={alt} />;
};
